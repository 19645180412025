import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    $('#nav-notifs-btn').addClass('activityindicator')
    // Remove up to date message if any
    $('.no-notifs-message').remove();
    // Remove 5th notif
    $('#notifications-panel-body-inner a:nth-child(5)').remove();
    // TODO: Insert new notification into panel (html not currently passed)
    $("#notifications-panel-body-inner").prepend(data.html);
  }
});
