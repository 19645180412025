import { initAutocomplete } from "../plugins/init_autocomplete";
import { initSwiper } from "../plugins/init_swiper";
import { initMasonry } from "../plugins/init_masonry";
import "../custom/time_zones";

const onPageLoad = () => {
  initAutocomplete();
  initSwiper();
  initMasonry();

  // Localise time zone-dependent elements
  $("#event_time_zone").populateTimeZoneSelect();
  $(".time_utc").utcToLocalTime();

  // Don't display ActionText attachments in galleries
  $(".attachment-gallery").removeClass("attachment-gallery");
}

document.addEventListener("turbo:load", onPageLoad, false);
document.addEventListener("modal_updated", onPageLoad, false);

import "../plugins/flatpickr";

if (!window.location.pathname.match("/contact"))
{
  var audio = document.getElementById("temp_audio");
  if (audio) audio.stop();
}

import "../custom/iframe_resize";