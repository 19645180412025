import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "form", "viewcontrols", "sortcontrols", "sortmenu"]
  static values = { defaultView: String, viewNames: Array }

  connect() {
    this.views = [];
    this.viewNamesValue.forEach(viewName => {
      this.views.push({
        name: viewName, 
        viewEl: document.getElementById(`${viewName}View`),
        buttonEl: document.getElementById(`${viewName}ViewButton`), 
        visible: false,
        default: (viewName == this.defaultViewValue),
      });
    });

    document.addEventListener("turbo:frame-load", function (e) {
      if(e.target.id == this.data.get("turbo-frame-id")) {
        this.initView();
      }
    }.bind(this));
    this.initView();
  }

  initView() {
    this.views.forEach(view => {
      view.viewEl = document.getElementById(`${view.name}View`);
      view.buttonEl = document.getElementById(`${view.name}ViewButton`);
    });

    var viewToShow = null;
    this.views.forEach(view => {    
      if(view.visible || (view.default && !viewToShow)) {
        viewToShow = view;
      }
    });
    this.showView(viewToShow);
  }

  onViewButtonClick(event) {
    const view = this.views.find( (view) => view.buttonEl.id == event.target.id );
    if(view) {
      this.showView(view);
    } else {
      console.log("ERROR: Couldn't find a matching view.");
    }
  }

  showView(viewToShow) {
    this.views.forEach(view => {
      if(view != viewToShow) {
        view.buttonEl.setAttribute("aria-expanded", false);
        view.viewEl.classList.add("hidden");
        view.visible = false;
      }
    });

    viewToShow.buttonEl.setAttribute("aria-expanded", true);
    viewToShow.viewEl.classList.remove("hidden");
    viewToShow.visible = true;

    const updateEvent = new CustomEvent("indexViewUpdated", { detail: viewToShow.name });
    document.dispatchEvent(updateEvent);
  }

  onPageChange(event) {
    if (event.currentTarget.dataset.autoscroll == "true") {
      const yOffset = -100; // Offset fixed navbar
      const y = this.containerTarget.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
    }
  }

  applyFilters(event) {
    $("#filters-modal").modal("hide");
  }

  resetFilters(event) {
    $('input[type=checkbox]').prop('checked', false);
    $("#filters-modal").modal("hide");

    event.preventDefault();
    this.submitForm();
  }

  removeFilter(event){
    var checkbox = $(event.srcElement.dataset.checkboxId);
    checkbox.prop('checked', false);

    event.preventDefault();
    this.submitForm();
  }

  submitForm() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 300)
  }
}