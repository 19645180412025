import { Controller } from 'stimulus'
import mapboxgl from 'mapbox-gl'
import "../custom/time_zones";

export default class extends Controller
{
  static targets = [ "wrapper", "panel", "paneltitle", "panelback", "togglepanel", "mapcontainer", "cmap", "placeholder",
                     "filterevents", "filterstories", "filterusers", "filterorgs", "filterplaces", "filterphotos" ];
  static values = { delayLoad: Boolean }

  connect() {    
    this.map = null;
    this.hoveringOverFeature = false;
    this.panelFeatures = [];
    this.prevPanelFeatures = [];
    this.initFilters();

    // AJAX request for delayed load required if requested, or if initial load only
    // includes a subset of content types (based on filters) which can be expanded on user request
    this.waitingForDelayedLoad = this.delayLoadValue || this.num_active_filters() < Object.keys(this.filter_targets).length;

    if (!this.delayLoadValue) {
      this.initMapbox();
    } else {
      this.cmapTarget.classList.add("d-none");
      this.panelTarget.classList.add("d-none");
    }

    this.togglepanelTarget.addEventListener("click", function() {
      this.panelTarget.classList.toggle("closed");
    }.bind(this));

    // Refresh the map when made visible on index pages
    document.addEventListener("indexViewUpdated", (e) => { 
      if (e.detail == "map") {
        this.refreshMap();
      }
    });

    // Called when the user enters a new search term
    $(this.mapcontainerTarget).on('refreshMap', function(event, data) {
      this.refreshMap();
    }.bind(this));

    // Objects for caching and keeping track of HTML marker objects (for performance)
    this.markers = {};
    this.markersOnScreen = {};

    // Create a popup, but don't add it to the map yet
    this.popup = new mapboxgl.Popup({ closeButton: false });

    // Mapbox doesn't currently support onClick events for custom HTML markers so we
    // have to handle this ourselves (https://github.com/mapbox/mapbox-gl-js/issues/7793)
    mapboxgl.Marker.prototype.onClick = function(clickHandler) {
      this._clickHandler = clickHandler;
      return this;
    };

    mapboxgl.Marker.prototype._onMapClick = function(t) 
    {
      const event = t.originalEvent;
      const target = event.target;
      const element = this._element;
      if (this._clickHandler && (target === element || element.contains((target)))) {
        this.togglePopup();
        this._clickHandler(event);
      }
    };

    // If the map is being used a toggle-able view, ensure it is
    // displayed correctly when shown for the first time
    const mapView = document.getElementById('mapView');
    if (mapView != null) {
      var observer = new MutationObserver(function() {
        if(mapView.style.display != 'none') {
          this.map.resize();
        }
      }.bind(this));
      observer.observe(mapView, { attributes: true, childList: true }); 
    }

    // If the map is being loaded as part of a page-in-page modal
    // we'll need to resize the map once the element has fully loaded
    document.addEventListener("modal_updated", () => { this.map.resize(); }, false);
  }

  refreshMap() 
  {
    if(!this.map) 
    {
      this.initMapbox();
    } 
    else
    {
      var featureCollection = this.getFeatureCollection();
      if (this.panelFeatures != featureCollection.features)
      {
        this.closeAndResetPanel();
        this.hidePopup();
        this.map.getSource('all-features').setData(featureCollection);
        this.fitMapToMarkers(this.map, featureCollection.features);
      }
    }
  }

  // Set boundaries of map so all markers fit (if there are any)
  fitMapToMarkers = (map, features) => 
  {
    if (features.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      features.forEach(({ geometry }) => bounds.extend(geometry.coordinates));
      map.fitBounds(bounds, { padding: 50, maxZoom: 15 });
    }
  };

  initMapbox() 
  {
    if (this.cmapTarget && !this.map) 
    {
      this.cmapTarget.classList.remove("d-none");
      this.placeholderTarget.classList.add("d-none");

      mapboxgl.accessToken = this.cmapTarget.dataset.mapboxApiKey;
      const map = this.map = new mapboxgl.Map({
        container: 'cmap',
        style: 'mapbox://styles/mapbox/streets-v10',
        pitchWithRotate: false,
        dragRotate: false,
        touchZoomRotate: false
      });

      // Close and reset the panel
      this.closeAndResetPanel();

      // Build a feature collection based on what filters the user has active
      var featureCollection = this.getFeatureCollection();

      map.on('load', function() {

        map.addSource('all-features', {
          type: 'geojson',
          data: featureCollection,
          generateId: true,
          cluster: true,
          clusterMaxZoom: 22,
          clusterRadius: 0,
          clusterProperties:
          {
            // Keep track of counts for specific content types
            'events': ['+', ['case', ['==', ['get', 'type'], 'event'], 1, 0]],
            'stories': ['+', ['case', ['==', ['get', 'type'], 'story'], 1, 0]],
            'users': ['+', ['case', ['==', ['get', 'type'], 'user'], 1, 0]],
            'orgs': ['+', ['case', ['==', ['get', 'type'], 'org'], 1, 0]],
            'places': ['+', ['case', ['==', ['get', 'type'], 'place'], 1, 0]],
            'photos': ['+', ['case', ['==', ['get', 'type'], 'gallery'], 1, 0]]
          }
        });

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'all-features',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'case',
              ["all", [">=", ['get', 'users'], 1], ["==", ['get', 'stories'], 0], ["==", ['get', 'events'], 0],
                      ["==", ['get', 'orgs'], 0], ["==", ['get', 'places'], 0]], '#ED6C19',

              ["all", [">=", ['get', 'events'], 1], ["==", ['get', 'stories'], 0], ["==", ['get', 'users'], 0],
                      ["==", ['get', 'orgs'], 0], ["==", ['get', 'places'], 0]], '#3DA2E6',

              ["all", [">=", ['get', 'stories'], 1], ["==", ['get', 'events'], 0], ["==", ['get', 'users'], 0],
                      ["==", ['get', 'orgs'], 0], ["==", ['get', 'places'], 0]], '#F4D225',

              ["all", ["==", ['get', 'stories'], 0], ["==", ['get', 'events'], 0], ["==", ['get', 'users'], 0], 
                      ["==", ['get', 'orgs'], 0], ["==", ['get', 'places'], 0]], '#C989C9',

              ["all", [">=", ['get', 'orgs'], 1], ["==", ['get', 'stories'], 0], ["==", ['get', 'events'], 0], ["==", ['get', 'users'], 0], 
                      ["==", ['get', 'places'], 0]], '#701470',

              ["all", [">=", ['get', 'places'], 1], ["==", ['get', 'stories'], 0], ["==", ['get', 'events'], 0], ["==", ['get', 'users'], 0], 
                      ["==", ['get', 'orgs'], 0]], '#44CF45',

              /* other */ '#fff'
              ],
            'circle-radius': 13,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#333',
            "circle-opacity": 0.75,
          }
        });

        map.addLayer({
            id: 'unclustered-points',
            type: 'circle',
            source: 'all-features',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': [
                'match', ['get', 'type'],
                'user', '#ED6C19',
                'event', '#3DA2E6',
                'story', '#F4D225',
                'org', '#701470',
                'place', '#44CF45',
                'gallery', '#C989C9',
                /* other */ '#fff'
                ],
              'circle-radius': 10,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#333',
              "circle-opacity": 0.75,
            },
            layout: {
              'circle-sort-key': [
                'match', ['get', 'type'],
                'user', 1,
                'story', 2,
                'gallery', 3,
                'org', 4,
                'place', 5,
                'event', 6,
                /* other */ 0
                ]
            }
        });

        // Mouse click / touch event handlers
        map.on('click', 'clusters', (e) => { this.onClusterClick(e); });
        map.on('click', 'unclustered-points', (e) => { this.updatePanel(e.features, false, true); });
        map.on('click', (e) => {
          if(!this.hoveringOverFeature && !this.panelFeatures || this.panelFeatures.length == 1) {
            this.hidePopup();
          }
        });

        // Automatically update panel list when moving the map
        map.on('moveend', () => { this.showFeaturesInView(); });

        // Toggle popups when hovering over map features
        map.on('mousemove', 'unclustered-points', (e) => { this.showPopup(e.features[0].geometry.coordinates, `${e.features[0].properties.name}`); });
        map.on('mousemove', 'clusters', (e) => { this.showPopup(e.features[0].geometry.coordinates, "Multiple items, click to view"); });
        map.on('mouseleave', 'unclustered-points', () => { this.hidePopup(); });
        map.on('mouseleave', 'clusters', () => { this.hidePopup(); });

        // Clean up panel and map zoom once source is fully loaded
        this.fitMapToMarkers(map, featureCollection.features);
        
      }.bind(this));

      // Add Zoom and Full Screen controls to map
      map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
      map.addControl(new mapboxgl.FullscreenControl());
    }
  }

  closeAndResetPanel() {
    this.showNoMapFeaturesMessage();
    $('#mappanel').removeClass("panel-content-story panel-content-event panel-content-user panel-content-org panel-content-place panel-content-gallery d-none");
    this.panelTarget.classList.add("closed");
    $(this.paneltitleTarget).html("");
  }

  getFeatureCollection() {          
    var featureCollection = {
      type: "FeatureCollection",
      features: []
    };

    if (this.filterusersTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.usermarkers));
    }
    if (this.filterplacesTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.placemarkers));
    }
    if (this.filterstoriesTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.storymarkers));
    }
    if (this.filtereventsTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.eventmarkers));
    }
    if (this.filterorgsTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.orgmarkers));
    }
    if (this.filterphotosTarget.classList.contains('active')) {
      featureCollection.features = featureCollection.features.concat(JSON.parse(this.cmapTarget.dataset.gallerymarkers));
    }
    return featureCollection;
  }

  showFeaturesInView()
  {
    if (!this.map.getSource('all-features') || !this.map.isSourceLoaded('all-features')) 
      return;

    const features = this.map.queryRenderedFeatures( {layers: ['clusters', 'unclustered-points']} );
    if(features && features.length > 0 && features != this.panelFeatures) {
      var isList = features.length > 1 || (features.length == 1 && features[0].properties.cluster);
      this.updatePanel(features, isList);
    }
  }

  showPopup(coords, text)
  {
    this.map.getCanvas().style.cursor = 'pointer';      
    this.popup.setLngLat(coords).setText(text).addTo(this.map);
    this.hoveringOverFeature = true;
  }

  hidePopup()
  {
    this.map.getCanvas().style.cursor = '';
    this.popup.remove();
    this.hoveringOverFeature = false;
  }

  onClusterClick(e)
  {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });
    const id = features[0].properties.cluster_id;

    var cluster = this.map.querySourceFeatures('all-features', {
      filter: ['all', ['has', 'point_count'], ['==', ['number', ['get', 'cluster_id']], id]]
    })[0];

    this.map.getSource('all-features').getClusterLeaves(id, cluster.properties.point_count, 0, function (err, leaves) 
    {
      this.updatePanel(leaves, true, true);
    }.bind(this));
  }

  updatePanel(features, isList, forceOpen = false)
  {
    // No need to refresh the panel if the contents aren't gonna change!
    if (this.panelFeatures == features) {
      return;
    }

    $(this.panelTarget).removeClass("panel-content-story panel-content-event panel-content-user panel-content-org panel-content-place panel-content-gallery");

    // Keep track of current and previous features listed in the panel
    this.prevPanelFeatures = this.panelFeatures;
    this.panelFeatures = [];

    // Open panel for single feature?
    if (!isList && features && features.length == 1 && !features[0].properties.cluster) 
    {
      const feature = features[0];
      const props = feature.properties;

      // Display popup above currently selected individual feature
      if (this.isFullMap()) {
        this.showPopup(feature.geometry.coordinates, props.name);
      }

      // Update panel header
      this.togglePanelHeaderElements(this.prevPanelFeatures.length > 1, false, '');

      // Server request to load full feature detail for the panel
      $.ajax({ 
        url: `/map_refresh_panel`,
        data: { show_list: isList, data: { type: props.type, id: props.database_id } },
        type: "get",
        success: function(data) { 
          this.showPanel(forceOpen);
         }.bind(this)
      });

      this.panelFeatures = features;
    } 
    else if(features) {
      const content = $("<div class='mappanel-content'></div>");
      var numItems = features.length;
      features.forEach(f => { if (f.properties.cluster) { numItems += (f.properties.point_count - 1) } });

      if(numItems > 300)
      {
        content.append(`<div> <p>There are <b>${numItems}</b> items here!</p><br>
                        <p>That's too many to list 💦</p><br>
                        <p>Try zooming in or clicking on a specific item to see more details.</p> </div>`);
        $('.mappanel-content').replaceWith(content);
        this.togglePanelHeaderElements(false, false, '');
        this.hidePopup();
      }
      else 
      {
        const mapSource = this.map.getSource('all-features');
        const col = $("<div class='col'></div>");
        const row = $("<div class='row mappanel-content-inner'></div>");

        features.forEach(feature => {
          const props = feature.properties;
          if(props.cluster) {
            mapSource.getClusterLeaves(props.cluster_id, props.point_count, 0, function(err, leaves) {
              leaves.forEach(leaf => { this.appendFeatureToPanelList(row, leaf); });
            }.bind(this));
          } else {
            this.appendFeatureToPanelList(row, feature);
          }
        });
        
        $('.mappanel-content').replaceWith( content.append(col.append(row)) );
        this.togglePanelHeaderElements(false, true, `${numItems} items here`);        
        this.showPanel(forceOpen);
        this.hidePopup();

        if(numItems == 0) {
          this.showNoMapFeaturesMessage();
        }
      }
    }
  }

  showPanel(forceOpen)
  {
    // We don't automatically show the panel on small devices / maps unless forced
    if (forceOpen || this.isFullMap()) {
      this.panelTarget.classList.remove("closed");
    }
  }

  onPanelBackClick(e)
  {
    this.updatePanel(this.prevPanelFeatures, this.panelFeatures.length > 1);
  }

  togglePanelHeaderElements(showBackButton, showTitle, titleText)
  {
    $(this.paneltitleTarget).html(titleText);
    $(this.panelbackTarget).addClass("d-none");
    $(this.paneltitleTarget).addClass("d-none");

    if(showBackButton) {
      $(this.panelbackTarget).removeClass("d-none");
    }
    else if (showTitle) {
      $(this.paneltitleTarget).removeClass("d-none");
    }

  }

  appendFeatureToPanelList(element, feature)
  {
    var linkEl = $(`<a class='maplist-item d-flex align-items-center'>
                      <div class='type-icon type-icon-${feature.properties.type} mr-3'></div>
                      <div class='list-item-text'><p>${feature.properties.name}</p></div>
                    </a>`);
    linkEl.on('click', function() { this.updatePanel([feature], false, true); }.bind(this) );
    linkEl.on('mouseover', function() { this.showPopup(feature.geometry.coordinates, feature.properties.name); }.bind(this) );
    element.append(linkEl);
    this.panelFeatures.push(feature);
  }

  initFilters()
  {
    this.filter_targets = {
      "events": this.filtereventsTarget, 
      "stories": this.filterstoriesTarget, 
      "users": this.filterusersTarget,
      "orgs": this.filterorgsTarget,
      "places": this.filterplacesTarget,
      "photos": this.filterphotosTarget,
    }

    // Setup initially active filters
    for (const [name, target] of Object.entries(this.filter_targets)) {
      if (this.data.get(name + '-active') === 'true') {
        target.classList.add("active");
        target.setAttribute('aria-pressed', true);
      }
    }
  }

  num_active_filters() {
    var count = 0;
    for (const [name, target] of Object.entries(this.filter_targets)) {
      if (target.classList.contains("active"))
        count = count + 1;
    }
    return count;
  }

  toggleFilter(event)
  {
    var filterElement = event.srcElement;
    filterElement.classList.toggle("active");
    filterElement.setAttribute('aria-pressed', filterElement.classList.contains("active"));

    // If someone toggles a filter while the placeholder is still up
    // make sure we actually load some data from the server
    if ( this.waitingForDelayedLoad ) {
      this.delayedLoad(event);
    } else {
      this.refreshMap();
    }    
    event.preventDefault();
  }

  delayedLoad(event) {
    if(this.waitingForDelayedLoad) {
      $.ajax({ 
        url: `/map_delayed_load`,
        type: "get"      
      });
    }

    this.waitingForDelayedLoad = false;
    event.preventDefault();
  }

  isFullMap()
  {
    return this.cmapTarget.clientWidth > 800;
  }

  showNoMapFeaturesMessage() 
  {
    $('.mappanel-content-inner').replaceWith( "<div class='col'><p>Nothing to see here! Try scrolling the map, zooming out, or changing your filters.</p></div>" );
  }
}