import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navBar", "navOverlay", "openOverlayBtn"]  

  connect() {
    this.$navBar = $(this.navBarTarget);
    this.$navOverlay = $(this.navOverlayTarget);

    // Event Handlers
    $(document).on('keyup', function(event) { if (event.key == "Escape") this.closeAllNavElements(); }.bind(this));

    this.$navBar.find('[aria-controls]').each(function(index, value) {
      $(value).on("click", () => { this.togglePanel($(value)) });
    }.bind(this));
    
    // Click outside panels to close all open / expanded panels (unless we just opened it with this click)
    $(document).on("click", (e) => {        
        this.$navBar.find("[aria-expanded='true']").each(function() {
          if ($(e.target).closest('.opened').length === 0) {
            this.togglePanel($(this));
          }
        }.bind(this));
    });
  }

  disconnect() {
    this.$navOverlay.off();
    this.$navBar.find('[aria-controls]').each(function(index, value) { $(value).off(); });
    $(this.openOverlayBtnTarget).off(); 
  }

  togglePanel($button) {
  
    // Show/hide the panel and update aria attributes accordingly
    var prevExpanded = $button.attr('aria-expanded');
    var $panel = $(`#${ $button.attr("aria-controls") }`);
  
    $button.attr("aria-expanded", (prevExpanded == "false" ? true : false));
    $button.toggleClass("opened");
    $panel.toggleClass("opened");
  
    // If this panel contains form elements, auto focus on the first one when expanding
    if (prevExpanded == "false") {
      var inputElements = $panel.find("input");
      if (inputElements.length) {
        inputElements.first().focus();
      }
    }
  
    // Close any other open nav bar panels
    this.$navBar.find(".opened").each(function() {
      var openedEl = $(this);
      if (!openedEl.is($button) && !openedEl.is($panel)) {
        openedEl.attr("aria-expanded", false);
        openedEl.removeClass("opened");
      }
    });
  }
  
  closeAllNavElements() {
    this.$navBar.find("[aria-expanded='true']").each(function() { this.togglePanel($(this)); }.bind(this));
  }
}