import imagesLoaded from "imagesloaded/imagesloaded.pkgd.min"
import Masonry from "masonry-layout/dist/masonry.pkgd.min";

const initMasonry = () => {
  const gridEl = document.querySelector(".masonry-grid");

  if(gridEl) {
    let masonry = new Masonry( gridEl, {
      columnWidth: '.masonry-sizer',
      itemSelector: '.masonry-item',
      percentPosition: true,
      gutter: 10,
      horizontalOrder: false
    });
  
    // Refresh the Masonry grid as each image loads in
    var imgLoad = imagesLoaded( gridEl);
    imgLoad.on( 'progress', function() {
      masonry.layout();
    });

    // Refresh the grid when made visible on index pages
    document.addEventListener("indexViewUpdated", (e) => { 
      if (e.detail == "gallery") {
        masonry.layout();
      }
    });
  }
  
}

export { initMasonry };